import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import React from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import FormLabelCustom from "./FormLabel";
import { Select } from "chakra-react-select";

const MultiSelectForm: React.FC<{
  control: Control<any>;
  label: string;
  labelProps?: any;
  name: string;
  placeholder?: string;
  rules?: RegisterOptions<any>;
  options: { label: string; value: string }[];
  onChange?: (value: string[] | number[]) => void;
}> = ({
  control,
  label,
  rules,
  name,
  placeholder,
  options,
  labelProps = {},
  onChange,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange: onChangeFunc, name, value },
        fieldState: { invalid, error },
      }) => {
        console.log(value);

        return (
          <FormControl size="sm" isInvalid={invalid}>
            <FormLabelCustom label={label} name={name} props={labelProps} />
            <Select
              aria-errormessage="error"
              placeholder={placeholder}
              size={"sm"}
              options={options}
              isMulti={true}
              onChange={(val) => {
                onChangeFunc(val);
                if (onChange) {
                  onChange(val.map((v) => v.value));
                }
              }}
              value={value}
              name={name}
            ></Select>
            <FormErrorMessage id="error">{error?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default MultiSelectForm;
