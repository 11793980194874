import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import SelectForm from "components/SelectForm";
import { queryClient } from "index";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import {
  CustomerListResponse,
  findAllClients,
  saveManualMovement,
} from "services/api.service";
import { MovementsType, MovementsTypeDescription } from "types/Movement";
import { maskDocument } from "utils/formatters";

interface AddForm {
  customerId: SelectOptionType;
  transactionCode: string;
  installments?: string;
  movementType: MovementsType;
  flagCard?: string;
  amount: number;
  amountLiquidFromGateway?: number;
  orderAt: string;
  firstDigits?: string;
  lastDigits?: string;
  terminalId?: string;
}

const AddManualOrder: React.FC = () => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<AddForm>({
    defaultValues: {
      installments: "1",
    },
  });

  const save = async (dataForm: AddForm) => {
    setLoading(true);
    saveManualMovement({
      ...dataForm,
      customerId: dataForm.customerId.value,
    })
      .then(() => {
        queryClient.refetchQueries({
          queryKey: ["findAllMovements"],
          type: "active",
        });
        toast({
          title: "Sucesso",
          description: "Recorrência criada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao criar Recorrência",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    reset({
      installments: "1",
      orderAt: new Date().toISOString().split(".")[0],
    });
  }, [reset]);

  return (
    <>
      <CustomButton
        onClick={() => {
          onOpen();
          reset();
        }}
        variant="outline"
        colorScheme="brand"
      >
        <>
          <FaPlus />
          <Text ml={2}>Lançamento Manual</Text>
        </>
      </CustomButton>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Venda</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <FormRemoteSelectInput
                  control={control}
                  label="Cliente"
                  noOptionsMessage={() => {
                    return <>Nenhuma opção encontrada. </>;
                  }}
                  registerOptions={{
                    required: "a seleção do recebedor é obrigatório",
                  }}
                  loadDataFn={({ value, cb }) =>
                    findAllClients({
                      filter: value,
                      limit: 20,
                      page: 0,
                    }).then((retorno: CustomerListResponse) => {
                      if (retorno?.registers?.length > 0) {
                        cb(
                          retorno.registers?.map((d) => ({
                            label: `${d.name} - ${maskDocument(
                              d.document_number
                            )}`,
                            value: d.id,
                          }))
                        );
                      } else {
                        cb([]);
                      }
                    })
                  }
                  name="customerId"
                />
                <InputForm
                  control={control}
                  type="text"
                  label="Id Transação"
                  name="transactionCode"
                  required
                  rules={{ required: "O id da transação é obrigatório" }}
                />
                <InputNumber
                  control={control}
                  label="Valor"
                  name="amount"
                  rules={{ required: "O valor é obrigatório" }}
                />
                <InputForm
                  control={control}
                  type="number"
                  label="Parcelas"
                  name="installments"
                />
                <InputForm
                  control={control}
                  type="datetime-local"
                  label="Data da Venda"
                  name="orderAt"
                  rules={{ required: "A data da venda é obrigatória" }}
                />
                <SelectForm
                  control={control}
                  label="Tipo de Movimento"
                  name="movementType"
                  labelProps={{ w: "100%", textAlign: "left", size: "sm" }}
                  options={Object.keys(MovementsType).map((key) => ({
                    label: MovementsTypeDescription[key],
                    value: key,
                  }))}
                  rules={{ required: "O tipo de movimento é obrigatório" }}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AddManualOrder;
